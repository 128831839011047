
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      dialog: false,
      lastTimeStamp: Date.now()
    };
  }, // data
  computed: {
    ...mapState('usermgmt', ['users', 'user', 'showDialog'])
  },
  watch: {
    showDialog() {
      this.dialog = this.showDialog;
    }
  },
  async created() {
    await this.fetchUsers();
  },
  methods: {
    ...mapActions('usermgmt', [
      'fetchUsers',
      'fetchUserGroup',
      'fetchUserGroupHierarchy'
    ]),
    ...mapActions('queue', [
      'fetchStoresList',
      'fetchFloorListByStore',
      'fetchPosListByStore'
    ]),
    refreshPage() {
      this.lastTimeStamp = Date.now();
    }
    // refreshPage
  } // methods
}; // export default
